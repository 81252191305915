<template>
  <v-app>
    <div id="app">
      <Header />
      <router-view />
      <MessageDialog />
      <ContactDialog />
      <footer
        class="pt-6 pb-6 d-flex justify-center"
        style="color: #939393; font-weight: 200"
      >
        ©WELCOMEBUD. All rights reserved
      </footer>
    </div>
    <div class="contact-btn">
      <v-btn
        @click="showContactDialog"
        color="customdark"
        fab
        x-large
        dark
        class="mt-4 white--text"
      >
        <v-icon>mdi-comment</v-icon>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
import Header from '@/components/header/Header.vue'
import MessageDialog from '@/components/MessageDialog.vue'
import ContactDialog from '@/components/ContactDialog.vue'
export default {
  components: {
    Header,
    MessageDialog,
    ContactDialog
  },
  created () {
    window.getApp = this
  },
  methods: {
    showContactDialog () {
      window.getApp.$emit('SHOW_CONTACT_DIALOG')
    }
  }
}
</script>

<style lang="scss">
@import "scss/main.scss";
.contact-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}
</style>
